import { Injectable } from '@angular/core';
import { initializeApp } from "firebase/app";
import { getFirestore, } from "firebase/firestore";
import { environment } from '../environments/firebaseConfiguration';
import { getDoc, setDoc, doc, getDocs, collection,where,query  } from "firebase/firestore";


@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    initApp = initializeApp(environment.firebaseConfig);
    db = getFirestore(this.initApp);
    collectionName = environment.collectionName;
    userCollection = environment.userCollection;
    transactionRef = collection(this.db, this.collectionName);
    transIdSet = new Set();
    prodMap = new Map();
    transactionLit = [];
    filterScans = [];
    count = 0

    // async getAllTransaction() {
    //     const querySnapshot = await getDocs(collection(this.db, this.collectionName));
    //     querySnapshot.forEach((doc) => {
    //         this.transactionLit.push(doc.data());
    //     })
    //     return this.transactionLit;
    // }

     async getAllUsers() {
        let userList =[];
        const querySnapshot = await getDocs(collection(this.db, this.userCollection));
        querySnapshot.forEach((users) => {
            userList.push(users.data());
        })
        return userList;
    }

    //filter data by transactionid,start date,end date
    async filterTransFromToDate(transId, fromDate, toDate, userName) {
        const citiesRef =  collection(this.db, this.collectionName)
        const filtereddata = [];
        if (transId && !fromDate && !toDate && !userName) {
            const tId = query(citiesRef, where("transaction_id", "==", transId));
            // const tId = query(citiesRef, where('transaction_id', '<=', transId.toLowerCase()), where('transaction_id', '>=', transId.toUpperCase()));
            await this.querySnapshot(filtereddata, tId);
        } else if (transId && fromDate && toDate && !userName) {
            const q = query(citiesRef, where("transaction_id", "==", transId), where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            // const q = query(citiesRef, where('transaction_id', '<=', transId.toLowerCase()), where('transaction_id', '>=', transId.toUpperCase()), where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            await this.querySnapshot(filtereddata, q);
        } else if (fromDate && toDate && !transId && !userName) {
            const dateq = query(citiesRef, where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            await this.querySnapshot(filtereddata, dateq);
        } else if (userName && !fromDate && !toDate && !transId) {
            const uName = query(citiesRef, where("username", "==", userName));
            await this.querySnapshot(filtereddata, uName);
        } else if (userName && fromDate && toDate && transId) {
            const q = query(citiesRef, where("transaction_id", "==", transId),  where("username", "==", userName), where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            // const q = query(citiesRef, where('transaction_id', '<=', transId.toLowerCase()), where('transaction_id', '>=', transId.toUpperCase()),  where("username", "==", userName), where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            await this.querySnapshot(filtereddata, q);
        } else if (userName && fromDate && toDate && !transId) {
            const q = query(citiesRef, where("username", "==", userName), where("created_on", ">=", fromDate), where("created_on", "<=", toDate));
            await this.querySnapshot(filtereddata, q);
        } else if (userName && !fromDate && !toDate && transId) {
            const q = query(citiesRef, where("username", "==", userName), where("transaction_id", "==", transId));
            // const q = query(citiesRef, where("username", "==", userName), where('transaction_id', '<=', transId.toLowerCase()), where('transaction_id', '>=', transId.toUpperCase()));
            await this.querySnapshot(filtereddata, q);
        } else {
            const citiesRef = collection(this.db, this.collectionName)
            await this.querySnapshot(filtereddata, citiesRef);
        }
        return filtereddata;
    // Latest code end
    }

    async querySnapshot(filtereddata, q){
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
                if (doc.exists()) {
                    const oneData = await doc.data()
                    const formateddata = FirebaseService.returnTransDateFormat(oneData)
                    filtereddata.push(formateddata)
                } else {
                    console.log("Document does not exist")
                }
            })
    }
   


    static returnTransDateFormat(data) {
        const transId = data['transaction_id'];
        const uName = data['username'];
        let createOn:number
        let startDate:number
        let endDate:number
        try{
         createOn = data['created_on']['seconds']
       }catch(e){
         createOn=0
            console.log("Date not found")
       }
        try{
            startDate = data['startDate']['seconds']
            endDate = data['endDate']['seconds']
       }catch(e){
        startDate=0
        endDate=0
            console.log("Start or End Date not found")
       }
        let dateDMY = new Date(createOn * 1000).toLocaleDateString("en-US");
        let startDMY = new Date(createOn *1000).toLocaleString('en-us',{timeZone:'UTC'})
        let endDMY = new Date(endDate *1000).toLocaleString('en-us',{timeZone:'UTC'})
        const obj = {transactionId: `${transId}`, createdDate: `${dateDMY}`, username: `${uName}`, startDate: `${startDMY.replace(',','')}`, endDate: `${endDMY.replace(',','')}` }
        return obj;

    }


    //retrive data by transaction id
    async getDataByTransId(transId: string) {
        const pSnap = await getDocs(collection(this.db, `${environment.collectionName}/${transId}/products`));
        const parts: any = [];
        let index = 0
        pSnap.forEach((doc1) => {
            const oneTrData = doc1.data()
            index = index + 1
            const obj = {
                slno: index,
                lotNo: `${oneTrData['lot_no'] ? oneTrData['lot_no'] : ''}`,
                partNo: `${oneTrData['part_no'] ? oneTrData['part_no'] : ''}`,
                remark: oneTrData['remark'],
                verification: oneTrData['master_data'],
                imgurl: oneTrData['img_url'],
            }
            parts.push(obj)

        })
        return parts;
    };

    async filterTransactionsByLotPart(filterRole: Object) {
        const transId = filterRole['transactionId'] ? filterRole['transactionId'] : ''
        const lotNo = filterRole['lotNo']
        const partNo = filterRole['partNo']
        const fDate = filterRole['fromDate'] ? filterRole['fromDate'] : ''
        const tDate = filterRole['toDate'] ? filterRole['toDate'] : ''
        const uName = filterRole['username'] ? filterRole['username'] : ''
        let filterdata = []

        const dataL1 = await this.filterTransFromToDate(transId, fDate, tDate, uName)

        
            dataL1.forEach(async ee => {
                if (lotNo && partNo) {
                    const transId = ee['transactionId']
                    const citiesRef = collection(this.db, `${environment.collectionName}/${transId}/products/`);
                    const q = query(citiesRef, where("lot_no", "==", lotNo), where("part_no", "==", partNo));
                    // const q = query(citiesRef, where('lot_no', '<=', lotNo.toLowerCase()), where('lot_no', '>=', lotNo.toUpperCase()), where('part_no', '<=', partNo.toLowerCase()), where('part_no', '>=', partNo.toUpperCase()));
                    await this.dataRetrievePartLotNo(filterdata, ee, q)
                } else if (lotNo) {
                    const transId = ee['transactionId']
                    const citiesRef = collection(this.db, `${environment.collectionName}/${transId}/products/`);
                    const q = query(citiesRef, where("lot_no", "==", lotNo));
                    // const q = query(citiesRef, where('lot_no', '<=', lotNo.toLowerCase()), where('lot_no', '>=', lotNo.toUpperCase()));
                    await this.dataRetrievePartLotNo(filterdata, ee, q)
                } else if (partNo) {
                    const transId = ee['transactionId']
                    const citiesRef = collection(this.db, `${environment.collectionName}/${transId}/products/`);
                    const q = query(citiesRef, where("part_no", "==", partNo));
                    // const q = query(citiesRef, where('part_no', '<=', partNo.toLowerCase()), where('part_no', '>=', partNo.toUpperCase()));
                    await this.dataRetrievePartLotNo(filterdata, ee, q)
                } else {
                    const transId = ee['transactionId']
                    const fDate = ee['fromDate']
                    const tDate = ee['toDate']
                    const citiesRef = collection(this.db, `${environment.collectionName}/${transId}/products/`);
                    const q = query(citiesRef, where("created_on", ">=", fDate), where("created_on", "<=", tDate));
                    await this.dataRetrievePartLotNo(filterdata, ee, q)
                }
            // })
        })
        //return filterdata;
        return new Promise((resolve, reject) => {
            resolve(filterdata);
       });
    }

    static returnTransDetailDateForLotPartNo(transId, data) {
        let createOn: number
        try {
            createOn = data['created_on']['seconds']
        } catch (e) {
            createOn = 0
            console.log("Date not found")
        }
        let dateDMY = new Date(createOn * 1000).toLocaleDateString("en-US");
        const obj = { transactionId: `${transId}`, createdDate: `${dateDMY}`, username: "" }
        return obj;

    }

     async dataRetrievePartLotNo(filterdata, ee, q){
        const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc)  => {
                if (doc.exists()) {
                    const oneData = await doc.data()
                    const transId = ee['transactionId']
                    const formateddata = FirebaseService.returnTransDetailDateForLotPartNo(transId, oneData)
                    filterdata.push(formateddata)
                } else {
                    console.log("Document does not exist")
                }
        })
        
        // return filterdata
        return new Promise((resolve, reject) => {
            resolve(filterdata);
       });
     }
}