import { Component, OnInit } from '@angular/core';
import { NbSpinnerService } from '@nebular/theme';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private spinner$: NbSpinnerService, public authService: AuthService) { }

  ngOnInit(): void {
    this.spinner$.load();
  }

  // login(){
  //   this.msalService.loginPopup().subscribe((response: AuthenticationResult) =>{
  //     this.msalService.instance.setActiveAccount(response.account)
  //   });
  // }

}
