import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  users = {
    uName: { name: 'test', picture: '' },
  };

  userMenu = [{ title: 'Log out',  icon: 'log-out-outline', link: '/authentication/logout' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private layoutService: LayoutService,
              private authService: AuthService,
              private router: Router) 
              {
                this.menuService.onItemClick()
                .subscribe((event) => {
                  this.onContextItemSelection(event.item.title);
                });
              }
  

  ngOnInit() {

      this.authService.afAuth.authState.subscribe((user) => {
        if (user) {
          this.users.uName['name'] = user.displayName;
          this.user = this.users.uName
        } 
      });  

       
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  onContextItemSelection(title) {
    if(title === 'Log out'){
      this.authService.SignOut();
      this.router.navigate(['/login']);
    }
  }
}
