import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    link: '/pages/iot-dashboard',
  },
  {
    title: 'Reports',
    icon: 'grid-outline',
    children: [
      {
        title: 'Return Transaction Details',
        link: '/pages/tables/smart-table',
      },
      {
        title: 'Search For Transaction',
        link: '/pages/tables/tree-grid',
      },
      // {
      //   title: 'Data Tables',
      //   link: '/pages/tables/datatables',
      // },
    ],
  },
];
