<section class="vh-100" style="background-color: #508bfc;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow-2-strong" style="border-radius: 1rem;">
            
                <div class="card-body p-5 text-center">
                <div class="row">
                    <div class="col-sm-4 logo-container mb-3">
                        <img src="../../assets/images/globus-medical.png"  width="80" height="75">
                    </div>
                    <div class="col-sm-8">
                        <h5 class="mb-3" style="font-size: large;">Welcome to Globus Medical</h5> 
                        <h4 class="mb-3" style="font-size: large;">Dashboard Login</h4> 
                    </div> 
                </div>
                <button class="btn btn-lg btn-block btn-primary" style="background-color: #0e0d0d;" (click)="authService.Login()"
                    type="submit"><img src="https://www.freepnglogos.com/uploads/microsoft-window-logo-emblem-0.png" width="40" height="40"> Sign in with microsoft</button>  
                </div>
          </div>
        </div>
      </div>
    </div>
  </section>