import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';
import { FirebaseService } from '../../firebase.service';

@Injectable()
export class SmartTableService extends SmartTableData {

  data1 = [{
    slno: 1,
    transactionId: 'Mark',
    createdDate: '01-12-2022',
    username: '@mdo',
    startDate: '11-11-2022',
    endDate: '01-12-2022',
    remark:true,
    verification:false,
    // imgurl:'<img src="https://firebasestorage.googleapis.com/v0/b/warehouse-vision.appspot.com/o/bdc5b505-579a-4a01-ba53-b86ee3f25631.jpeg?alt=media&token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVhNWY2NDYxMjA4Y2ZmMGVlYzgwZDFkYmI1MjgyZTkyMDY0MjAyNWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2FyZWhvdXNlLXZpc2lvbiIsImF1ZCI6IndhcmVob3VzZS12aXNpb24iLCJhdXRoX3RpbWUiOjE2NTc3Mjk5ODAsInVzZXJfaWQiOiJndjVXNHgyWGg3YlVJUXduaGUzQTZ6MHl5MEgzIiwic3ViIjoiZ3Y1VzR4MlhoN2JVSVF3bmhlM0E2ejB5eTBIMyIsImlhdCI6MTY1NzcyOTk4MCwiZXhwIjoxNjU3NzMzNTgwLCJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.HeyH-RjWLMsUUS-m3GRpctnGalPkxcq9AGuoAZ1Ok-if-DwLT7q6wAnqCssjliFdvKXkcW7ST0Bmr_WOgn9i5svuYkWFhGWTuyP_aHPrMJXGUjbpswSWmbF_pGidP3ZLZdGZK1aW-IXoKQsj7d5rOBAiUg8Tgt0EMolCl-3Ua3D610QIkQrXvRgdb25v50Ch8RV6XpgtsezJoGj9Igl-w9fnmtIAP_EGGKL6YD9AaQUJzLuiruRka0uBUQzR7dgjvJvgJxU5NEMn9dXTLTYfXpAnUM-QAKAuiP2BPnPKJUqfXV3V7WUPYbFt-ovD_j9zQK2S9kR2GRbrUW_5Np829g" width="40" height="40"/>',
    // imgurl1:"<img src='https://www.shutterstock.com/image-photo/surreal-concept-roll-world-dice-260nw-1356798002.jpg' class='imageClass' width='40' height='40' />",
    imgurl:['https://firebasestorage.googleapis.com/v0/b/warehouse-vision.appspot.com/o/bdc5b505-579a-4a01-ba53-b86ee3f25631.jpeg?alt=media&token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVhNWY2NDYxMjA4Y2ZmMGVlYzgwZDFkYmI1MjgyZTkyMDY0MjAyNWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2FyZWhvdXNlLXZpc2lvbiIsImF1ZCI6IndhcmVob3VzZS12aXNpb24iLCJhdXRoX3RpbWUiOjE2NTc3Mjk5ODAsInVzZXJfaWQiOiJndjVXNHgyWGg3YlVJUXduaGUzQTZ6MHl5MEgzIiwic3ViIjoiZ3Y1VzR4MlhoN2JVSVF3bmhlM0E2ejB5eTBIMyIsImlhdCI6MTY1NzcyOTk4MCwiZXhwIjoxNjU3NzMzNTgwLCJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.HeyH-RjWLMsUUS-m3GRpctnGalPkxcq9AGuoAZ1Ok-if-DwLT7q6wAnqCssjliFdvKXkcW7ST0Bmr_WOgn9i5svuYkWFhGWTuyP_aHPrMJXGUjbpswSWmbF_pGidP3ZLZdGZK1aW-IXoKQsj7d5rOBAiUg8Tgt0EMolCl-3Ua3D610QIkQrXvRgdb25v50Ch8RV6XpgtsezJoGj9Igl-w9fnmtIAP_EGGKL6YD9AaQUJzLuiruRka0uBUQzR7dgjvJvgJxU5NEMn9dXTLTYfXpAnUM-QAKAuiP2BPnPKJUqfXV3V7WUPYbFt-ovD_j9zQK2S9kR2GRbrUW_5Np829g'],
  }, 
  {
    slno: 2,
    transactionId: 'Jacob',
    createdDate: "2019-08-14T00:45:51",
    username: '@fat',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
    remark:false,
    verification:true,
    // imgurl:'<a href="/"><img src="./assets/images/camera1.jpg" width="40" height="40"/></a>'
    imgurl:['https://firebasestorage.googleapis.com/v0/b/warehouse-vision.appspot.com/o/73e96a91-44de-4986-a435-4ab02333eeeb.jpeg?alt=media&token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVhNWY2NDYxMjA4Y2ZmMGVlYzgwZDFkYmI1MjgyZTkyMDY0MjAyNWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2FyZWhvdXNlLXZpc2lvbiIsImF1ZCI6IndhcmVob3VzZS12aXNpb24iLCJhdXRoX3RpbWUiOjE2NTc3Mjk5ODAsInVzZXJfaWQiOiJndjVXNHgyWGg3YlVJUXduaGUzQTZ6MHl5MEgzIiwic3ViIjoiZ3Y1VzR4MlhoN2JVSVF3bmhlM0E2ejB5eTBIMyIsImlhdCI6MTY1NzcyOTk4MCwiZXhwIjoxNjU3NzMzNTgwLCJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.HeyH-RjWLMsUUS-m3GRpctnGalPkxcq9AGuoAZ1Ok-if-DwLT7q6wAnqCssjliFdvKXkcW7ST0Bmr_WOgn9i5svuYkWFhGWTuyP_aHPrMJXGUjbpswSWmbF_pGidP3ZLZdGZK1aW-IXoKQsj7d5rOBAiUg8Tgt0EMolCl-3Ua3D610QIkQrXvRgdb25v50Ch8RV6XpgtsezJoGj9Igl-w9fnmtIAP_EGGKL6YD9AaQUJzLuiruRka0uBUQzR7dgjvJvgJxU5NEMn9dXTLTYfXpAnUM-QAKAuiP2BPnPKJUqfXV3V7WUPYbFt-ovD_j9zQK2S9kR2GRbrUW_5Np829g'],
  }, {
    slno: 3,
    transactionId: 'Larry',
    createdDate: "2019-08-14T00:45:51",
    username: '@twitter',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
    remark:true,
    verification:true,
    // imgurl:'<img src="./assets/images/camera1.jpg" width="40" height="40"/>'
    imgurl:['https://firebasestorage.googleapis.com/v0/b/warehouse-vision.appspot.com/o/bdc5b505-579a-4a01-ba53-b86ee3f25631.jpeg?alt=media&token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVhNWY2NDYxMjA4Y2ZmMGVlYzgwZDFkYmI1MjgyZTkyMDY0MjAyNWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2FyZWhvdXNlLXZpc2lvbiIsImF1ZCI6IndhcmVob3VzZS12aXNpb24iLCJhdXRoX3RpbWUiOjE2NTc3Mjk5ODAsInVzZXJfaWQiOiJndjVXNHgyWGg3YlVJUXduaGUzQTZ6MHl5MEgzIiwic3ViIjoiZ3Y1VzR4MlhoN2JVSVF3bmhlM0E2ejB5eTBIMyIsImlhdCI6MTY1NzcyOTk4MCwiZXhwIjoxNjU3NzMzNTgwLCJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.HeyH-RjWLMsUUS-m3GRpctnGalPkxcq9AGuoAZ1Ok-if-DwLT7q6wAnqCssjliFdvKXkcW7ST0Bmr_WOgn9i5svuYkWFhGWTuyP_aHPrMJXGUjbpswSWmbF_pGidP3ZLZdGZK1aW-IXoKQsj7d5rOBAiUg8Tgt0EMolCl-3Ua3D610QIkQrXvRgdb25v50Ch8RV6XpgtsezJoGj9Igl-w9fnmtIAP_EGGKL6YD9AaQUJzLuiruRka0uBUQzR7dgjvJvgJxU5NEMn9dXTLTYfXpAnUM-QAKAuiP2BPnPKJUqfXV3V7WUPYbFt-ovD_j9zQK2S9kR2GRbrUW_5Np829g'],
  }, {
    slno: 4,
    transactionId: 'John',
    createdDate: "2019-08-14T00:45:51",
    username: '@snow',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
    remark:false,
    verification:false,
    imgurl:'https://firebasestorage.googleapis.com/v0/b/warehouse-vision.appspot.com/o/73e96a91-44de-4986-a435-4ab02333eeeb.jpeg?alt=media&token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVhNWY2NDYxMjA4Y2ZmMGVlYzgwZDFkYmI1MjgyZTkyMDY0MjAyNWEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vd2FyZWhvdXNlLXZpc2lvbiIsImF1ZCI6IndhcmVob3VzZS12aXNpb24iLCJhdXRoX3RpbWUiOjE2NTc3Mjk5ODAsInVzZXJfaWQiOiJndjVXNHgyWGg3YlVJUXduaGUzQTZ6MHl5MEgzIiwic3ViIjoiZ3Y1VzR4MlhoN2JVSVF3bmhlM0E2ejB5eTBIMyIsImlhdCI6MTY1NzcyOTk4MCwiZXhwIjoxNjU3NzMzNTgwLCJlbWFpbCI6InRlc3RAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbInRlc3RAZ21haWwuY29tIl19LCJzaWduX2luX3Byb3ZpZGVyIjoicGFzc3dvcmQifX0.HeyH-RjWLMsUUS-m3GRpctnGalPkxcq9AGuoAZ1Ok-if-DwLT7q6wAnqCssjliFdvKXkcW7ST0Bmr_WOgn9i5svuYkWFhGWTuyP_aHPrMJXGUjbpswSWmbF_pGidP3ZLZdGZK1aW-IXoKQsj7d5rOBAiUg8Tgt0EMolCl-3Ua3D610QIkQrXvRgdb25v50Ch8RV6XpgtsezJoGj9Igl-w9fnmtIAP_EGGKL6YD9AaQUJzLuiruRka0uBUQzR7dgjvJvgJxU5NEMn9dXTLTYfXpAnUM-QAKAuiP2BPnPKJUqfXV3V7WUPYbFt-ovD_j9zQK2S9kR2GRbrUW_5Np829g',
  }, {
    slno: 5,
    transactionId: 'Jack',
    createdDate: "2019-08-14T00:45:51",
    username: '@jack',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 6,
    transactionId: 'Ann',
    createdDate: "2019-08-14T00:45:51",
    username: '@ann',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 7,
    transactionId: 'Barbara',
    createdDate: "2019-08-14T00:45:51",
    username: '@barbara',
    startDate:"2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 8,
    transactionId: 'Sevan',
    createdDate: "2019-08-14T00:45:51",
    username: '@sevan',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 9,
    transactionId: 'Ruben',
    createdDate: "2019-08-14T00:45:51",
    username: '@ruben',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 10,
    transactionId: 'Karen',
    createdDate: "2019-08-14T00:45:51",
    username: '@karen',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 11,
    transactionId: 'Mark',
    createdDate: "2019-08-14T00:45:51",
    username: '@mark',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 12,
    transactionId: 'Jacob',
    createdDate: "2019-08-14T00:45:51",
    username: '@jacob',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 13,
    transactionId: 'Haik',
    createdDate: "2019-08-14T00:45:51",
    username: '@haik',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 14,
    transactionId: 'Garegin',
    createdDate: "2019-08-14T00:45:51",
    username: '@garegin',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    slno: 15,
    transactionId: 'Krikor',
    createdDate: "2019-08-14T00:45:51",
    username: '@krikor',
    startDate: "2019-08-14T00:45:51",
    endDate: "2019-08-14T00:45:51",
  }, {
    'slno': 16,
    'transactionId': 'Francisca',
    'createdDate': 'Brady',
    'username': '@Gibson',
    'startDate': 'franciscagibson@comtours.com',
    'endDate': 11,
  }, {
    'slno': 17,
    'transactionId': 'Tillman',
    'createdDate': 'Figueroa',
    'username': '@Snow',
    'startDate': 'tillmansnow@comtours.com',
    'endDate': 34,
  }, {
    'slno': 18,
    'transactionId': 'Jimenez',
    'createdDate': 'Morris',
    'username': '@Bryant',
    'startDate': 'jimenezbryant@comtours.com',
    'endDate': 45,
  }, {
    'slno': 19,
    'transactionId': 'Sandoval',
    'createdDate': 'Jacobson',
    'username': '@Mcbride',
    'startDate': 'sandovalmcbride@comtours.com',
    'endDate': 32,
  }, {
    'slno': 20,
    'transactionId': 'Griffin',
    'createdDate': 'Torres',
    'username': '@Charles',
    'startDate': 'griffincharles@comtours.com',
    'endDate': 19,
  }, {
    'slno': 21,
    'transactionId': 'Cora',
    'createdDate': 'Parker',
    'username': '@Caldwell',
    'startDate': 'coracaldwell@comtours.com',
    'endDate': 27,
  }, {
    'slno': 22,
    'transactionId': 'Cindy',
    'createdDate': 'Bond',
    'username': '@Velez',
    'startDate': 'cindyvelez@comtours.com',
    'endDate': 24,
  }, {
    'slno': 23,
    'transactionId': 'Frieda',
    'createdDate': 'Tyson',
    'username': '@Craig',
    'startDate': 'friedacraig@comtours.com',
    'endDate': 45,
  }, {
    'slno': 24,
    'transactionId': 'Cote',
    'createdDate': 'Holcomb',
    'username': '@Rowe',
    'startDate': 'coterowe@comtours.com',
    'endDate': 20,
  }, {
    'slno': 25,
    'transactionId': 'Trujillo',
    'createdDate': 'Mejia',
    'username': '@Valenzuela',
    'startDate': 'trujillovalenzuela@comtours.com',
    'endDate': 16,
  }, {
    'slno': 26,
    'transactionId': 'Pruitt',
    'createdDate': 'Shepard',
    'username': '@Sloan',
    'startDate': 'pruittsloan@comtours.com',
    'endDate': 44,
  }, {
    'slno': 27,
    'transactionId': 'Sutton',
    'createdDate': 'Ortega',
    'username': '@Black',
    'startDate': 'suttonblack@comtours.com',
    'endDate': 42,
  }, {
    'slno': 28,
    'transactionId': 'Marion',
    'createdDate': 'Heath',
    'username': '@Espinoza',
    'startDate': 'marionespinoza@comtours.com',
    'endDate': 47,
  }, {
    'slno': 29,
    'transactionId': 'Newman',
    'createdDate': 'Hicks',
    'username': '@Keith',
    'startDate': 'newmankeith@comtours.com',
    'endDate': 15,
  }, {
    'slno': 30,
    'transactionId': 'Boyle',
    'createdDate': 'Larson',
    'username': '@Summers',
    'startDate': 'boylesummers@comtours.com',
    'endDate': 32,
  }, {
    'slno': 31,
    'transactionId': 'Haynes',
    'createdDate': 'Vinson',
    'username': '@Mckenzie',
    'startDate': 'haynesmckenzie@comtours.com',
    'endDate': 15,
  }, {
    'slno': 32,
    'transactionId': 'Miller',
    'createdDate': 'Acosta',
    'username': '@Young',
    'startDate': 'milleryoung@comtours.com',
    'endDate': 55,
  }, {
    'slno': 33,
    'transactionId': 'Johnston',
    'createdDate': 'Brown',
    'username': '@Knight',
    'startDate': 'johnstonknight@comtours.com',
    'endDate': 29,
  }, {
    'slno': 34,
    'transactionId': 'Lena',
    'createdDate': 'Pitts',
    'username': '@Forbes',
    'startDate': 'lenaforbes@comtours.com',
    'endDate': 25,
  }, {
    'slno': 35,
    'transactionId': 'Terrie',
    'createdDate': 'Kennedy',
    'username': '@Branch',
    'startDate': 'terriebranch@comtours.com',
    'endDate': 37,
  }, {
    'slno': 36,
    'transactionId': 'Louise',
    'createdDate': 'Aguirre',
    'username': '@Kirby',
    'startDate': 'louisekirby@comtours.com',
    'endDate': 44,
  }, {
    'slno': 37,
    'transactionId': 'David',
    'createdDate': 'Patton',
    'username': '@Sanders',
    'startDate': 'davidsanders@comtours.com',
    'endDate': 26,
  }, {
    'slno': 38,
    'transactionId': 'Holden',
    'createdDate': 'Barlow',
    'username': '@Mckinney',
    'startDate': 'holdenmckinney@comtours.com',
    'endDate': 11,
  }, {
    'slno': 39,
    'transactionId': 'Baker',
    'createdDate': 'Rivera',
    'username': '@Montoya',
    'startDate': 'bakermontoya@comtours.com',
    'endDate': 47,
  }, {
    'slno': 40,
    'transactionId': 'Belinda',
    'createdDate': 'Lloyd',
    'username': '@Calderon',
    'startDate': 'belindacalderon@comtours.com',
    'endDate': 21,
  }, {
    'slno': 41,
    'transactionId': 'Pearson',
    'createdDate': 'Patrick',
    'username': '@Clements',
    'startDate': 'pearsonclements@comtours.com',
    'endDate': 42,
  }, {
    'slno': 42,
    'transactionId': 'Alyce',
    'createdDate': 'Mckee',
    'username': '@Daugherty',
    'startDate': 'alycedaugherty@comtours.com',
    'endDate': 55,
  }, {
    'slno': 43,
    'transactionId': 'Valencia',
    'createdDate': 'Spence',
    'username': '@Olsen',
    'startDate': 'valenciaolsen@comtours.com',
    'endDate': 20,
  }, {
    'slno': 44,
    'transactionId': 'Leach',
    'createdDate': 'Holcomb',
    'username': '@Humphrey',
    'startDate': 'leachhumphrey@comtours.com',
    'endDate': 28,
  }, {
    'slno': 45,
    'transactionId': 'Moss',
    'createdDate': 'Baxter',
    'username': '@Fitzpatrick',
    'startDate': 'mossfitzpatrick@comtours.com',
    'endDate': 51,
  }, {
    'slno': 46,
    'transactionId': 'Jeanne',
    'createdDate': 'Cooke',
    'username': '@Ward',
    'startDate': 'jeanneward@comtours.com',
    'endDate': 59,
  }, {
    'slno': 47,
    'transactionId': 'Wilma',
    'createdDate': 'Briggs',
    'username': '@Kidd',
    'startDate': 'wilmakidd@comtours.com',
    'endDate': 53,
  }, {
    'slno': 48,
    'transactionId': 'Beatrice',
    'createdDate': 'Perry',
    'username': '@Gilbert',
    'startDate': 'beatricegilbert@comtours.com',
    'endDate': 39,
  }, {
    'slno': 49,
    'transactionId': 'Whitaker',
    'createdDate': 'Hyde',
    'username': '@Mcdonald',
    'startDate': 'whitakermcdonald@comtours.com',
    'endDate': 35,
  }, {
    'slno': 50,
    'transactionId': 'Rebekah',
    'createdDate': 'Duran',
    'username': '@Gross',
    'startDate': 'rebekahgross@comtours.com',
    'endDate': 40,
  }, {
    'slno': 51,
    'transactionId': 'Earline',
    'createdDate': 'Mayer',
    'username': '@Woodward',
    'startDate': 'earlinewoodward@comtours.com',
    'endDate': 52,
  }, {
    'slno': 52,
    'transactionId': 'Moran',
    'createdDate': 'Baxter',
    'username': '@Johns',
    'startDate': 'moranjohns@comtours.com',
    'endDate': 20,
  }, {
    'slno': 53,
    'transactionId': 'Nanette',
    'createdDate': 'Hubbard',
    'username': '@Cooke',
    'startDate': 'nanettecooke@comtours.com',
    'endDate': 55,
  }, {
    'slno': 54,
    'transactionId': 'Dalton',
    'createdDate': 'Walker',
    'username': '@Hendricks',
    'startDate': 'daltonhendricks@comtours.com',
    'endDate': 25,
  }, {
    'slno': 55,
    'transactionId': 'Bennett',
    'createdDate': 'Blake',
    'username': '@Pena',
    'startDate': 'bennettpena@comtours.com',
    'endDate': 13,
  }, {
    'slno': 56,
    'transactionId': 'Kellie',
    'createdDate': 'Horton',
    'username': '@Weiss',
    'startDate': 'kellieweiss@comtours.com',
    'endDate': 48,
  }, {
    'slno': 57,
    'transactionId': 'Hobbs',
    'createdDate': 'Talley',
    'username': '@Sanford',
    'startDate': 'hobbssanford@comtours.com',
    'endDate': 28,
  }, {
    'slno': 58,
    'transactionId': 'Mcguire',
    'createdDate': 'Donaldson',
    'username': '@Roman',
    'startDate': 'mcguireroman@comtours.com',
    'endDate': 38,
  }, {
    'slno': 59,
    'transactionId': 'Rodriquez',
    'createdDate': 'Saunders',
    'username': '@Harper',
    'startDate': 'rodriquezharper@comtours.com',
    'endDate': 20,
  }, {
    'slno': 60,
    'transactionId': 'Lou',
    'createdDate': 'Conner',
    'username': '@Sanchez',
    'startDate': 'lousanchez@comtours.com',
    'endDate': 16,
  }];

  data = this.formatAllTransData();
  
  constructor(private fireService: FirebaseService){
    super();
  }

  formatAllTransData():any[] {
    const transactions = []
    // this.fireService.getAllTransaction().then((allData) => {
    //   allData.forEach((oneData, index) => {
    //     const obj = {
    //       slno:index+1,
    //       // id:`${oneData.lot_no?oneData.lot_no:''}`,
    //       transactionId: `${oneData.transaction_id?oneData.transaction_id:''}`,
    //       createdDate:`${oneData.create_date?oneData.create_date:''}`,
    //       username:`${oneData.user_name?oneData.user_name:''}`,
    //       startDate:`${oneData.start_date?oneData.start_date:''}`,
    //       endDate:`${oneData.end_date?oneData.end_date:''}`
    //     }
    //     transactions.push(obj)
    //   })
    // })
    return transactions
  }

  getData() {
    return this.data;
  }
}
